(function () {
    var productService = ['$http', function ($http) {

        this.getProducts = function () {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/products'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getProduct = function (productId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/product/'+productId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.updateProduct = function (productId, data) {
            console.log('put Test');
            return $http({
            method: 'PUT',
            data: data,
            url: 'https://api.af-media.org/product/'+productId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.addProduct = function(data) {
            return $http({
            method: 'POST',
            data: data,
            url: '../../api/products'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }
        
        this.updateProduct = function(id, data) {
            return $http({
            method: 'PUT',
            data: data,
            url: '../../api/products/'+id
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.deleteProduct = function(id) {
            return $http({
            method: 'DELETE',
            url: '../../api/products/'+id
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getProductSurveys = function (productId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/product/'+productId+'/surveys'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getProductApiFields = function (productId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/product/'+productId+'/apifields'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.addProductApiFields = function (productId, property) {
            return $http({
            method: 'POST',
            data: {property: property},
            url: 'https://api.af-media.org/product/'+productId+'/addapifields'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.updateProductApiFields = function (fieldId, property, order, visible, columnName, productId) {
            return $http({
            method: 'POST',
            data: {property: property, order: order, visible: visible, columnName: columnName},
            url: 'https://api.af-media.org/product/apifields/'+productId+'/'+fieldId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.deleteProductApiField = function (fieldId, productId) {
            return $http({
            method: 'DELETE',
            url: 'https://api.af-media.org/product/apifields/'+productId+'/'+fieldId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.addSurveyProductLink = function (surveyId, productId) {
            return $http({
            method: 'POST',
            data: {surveyId: surveyId, productId: productId},
            url: 'https://api.af-media.org/product/link-survey'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getZohoApiFields = function (module) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/zoho/getfields/'+module
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getZohoApiFieldsUmfrageergebnisse = function (module, productId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/zoho/getfieldsumfrageergbenisse/'+module+'/'+productId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getZohoApiMapping = function (module, productId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/zoho/apimapping/'+module+'/'+productId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.createZohoApiMapping = function (productId, propertyFieldId, zohoModule, zohoFieldId, label, apiName) {
            return $http({
            method: 'POST',
            data: {productId: productId, propertyFieldId: propertyFieldId, zohoModule: zohoModule, zohoFieldId: zohoFieldId, label: label, apiName: apiName},
            url: 'https://api.af-media.org/zoho/apimapping'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.deleteZohoApiMapping = function (module, propertyFieldId, productId) {
            return $http({
            method: 'DELETE',
            url: 'https://api.af-media.org/zoho/apimapping/'+module+'/'+productId+'/'+propertyFieldId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getSalesPlatform = function () {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/products/salesplatforms'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getZohoProducts = function (query) {

            let appendToUrl = (query == undefined || query.length <= 0) ? '' : '/'+query;
            let url = 'https://api.af-media.org/books/products'+appendToUrl;

            return $http({
            method: 'GET',
            url: url
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getGorillaProducts = function (query) {

            let appendToUrl = (query == undefined || query.length <= 0) ? '' : '/'+query;
            let url = 'https://api.af-media.org/gorilla/products'+appendToUrl;

            return $http({
            method: 'GET',
            url: url
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }


    }];

    angular.module('dashboard').service('productService', productService);
  
  } ());