(function() {
    var selectDetailController = ['$scope', '$mdToast', '$timeout', '$stateParams', '$state', 'selectService', function($scope, $mdToast, $timeout, $stateParams, $state, selectService ) {
        
        console.log('select detail controller loaded');

        $scope.addAnswer = {};
        $scope.addAnswer.value = '';
        $scope.addAnswer.parent = $stateParams.selectId;
        $scope.addAnswer.ktTag = '';

        $scope.subfield = false;

        $scope.subSelects = [];
        $scope.subSelectsFilter = [];

        $scope.defaultParent = 0;
        $scope.defaultParentTrigger = 0;

        $scope.parentId = $stateParams.selectId;

        selectService.getDetailsSelect($stateParams.selectId).then(function(data) {
            console.log(data);
            $scope.answers = data;
        }, function() {
            console.log('error');
        });

        selectService.getSelects($stateParams.selectId).then(function(data) {
            console.log(data);
            $scope.selects = data;
        }, function() {
            console.log('error');
        });

        selectService.getSubSelectParents().then(function(data) {
            console.log(data);
            $scope.parentSubSelect = data;
        }, function() {
            console.log('error');
        });

        $scope.addSelectAnswer = function() {
            console.log('add - select answer');
            console.log($scope.addAnswer);
            var postdata = $scope.addAnswer;
            postdata.typeId = parseInt($stateParams.selectId);
            // check if subfield otherwise take default parent
            if(postdata.parentSub){
                postdata.parent = parseInt(postdata.parentSub);
            } else {
                postdata.parent = parseInt(postdata.parent);
            }

            console.log(postdata);

            selectService.addSelectAnswer(postdata).then(function(data) {
                console.log(data);
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Ihre Änderungen wurden gespeichert')
                    .hideDelay(3000))
                $scope.answers = data;

                selectService.getSubSelectParents().then(function(data) {
                    console.log(data);
                    $scope.parentSubSelect = data;
                }, function() {
                    console.log('error');
                });

            }, function() {
                console.log('error');
            });

        }
        
        $scope.deleteSelectItem = function(id) {
            console.log('DELETE ITEM');

            selectService.deleteSelectItem(id).then(function(data) {
                console.log('delete select item response: ', data);
                $scope.answers = data;
            }, function() {
                console.log('error');
            });
        }

        $scope.changeDetector = function(id) {
            console.log('id: ', id);

            selectService.getDetailsSelect(id).then(function(data) {
                $scope.subSelects = data;
            }, function() {
                console.log('error');
            });

        }

        $scope.changeFilterDetector = function(id) {
            
            console.log('filter changed');
            console.log(id);

            selectService.getDetailsSelect(id).then(function(data) {
                $scope.subSelectsFilter = data;
            }, function() {
                console.log('error');
            });

        }
        
        $scope.changeSubFilterDetector = function(id) {
            console.log('filter changed');
            console.log(id);
        }

        $scope.changeFilterByParentDetector = function(id){
            console.log('parent sub filter change: ', id);
            
            selectService.getDetailsSelectFilterByParent(id).then(function(data) {
                $scope.answers = data;
            }, function() {
                console.log('error');
            });
        }
        
        $scope.$watch(
            function() { return $scope.subfield; },
                function(newValue, oldValue) {
                    if ( newValue !== oldValue ) {
                        if(!newValue){
                            console.log('loop test');
                            $scope.filter.parent = null;
                            $scope.addAnswer.parentSub = null;
                            $scope.addAnswer.parent = $stateParams.selectId;
                        }
                    }
                }
        );

    }];
    angular.module('dashboard').controller('selectDetailController', selectDetailController);
}());