(function() {
    var productSettingsController = ['$scope', '$state', '$stateParams', 'product', 'surveyService', 'productService', function($scope, $state, $stateParams,
         product, surveyService, productService) {
            
            console.log('productSettingsController loaded');

            console.log(product);

            $scope.updateProduct = function(){
                console.log('test');
                productService.updateProduct(product.id, {name: $scope.product.name, nickname: $scope.product.nickname, status: $scope.product.status}).then(function(data) {
                    $scope.surveys = data;
                }, function() {
                    console.log('error');
                });
            }

        $scope.product = product;

    }];
    angular.module('dashboard').controller('productSettingsController', productSettingsController);
}());