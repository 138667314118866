(function() {
    var optinDetailController = ['$scope', '$mdToast', '$timeout', '$stateParams', '$state', 'optinService', function($scope, $mdToast, $timeout, $stateParams, $state, optinService ) {
        
        console.log('optin detail controller loaded');

        $scope.tagsReadOnly = false;
        $scope.tagsDeleteable = false;

        $scope.demoHtml = '<form method="post" action="https://api.af-media.org/optin/action"><input type="hidden" id="" name="formId" value="[formHash]" /><input type="hidden" id="" name="utm_source" value="[WERT EINFÜGEN]" /><input type="hidden" id="" name="utm_medium" value="[WERT EINFÜGEN]" /><input type="hidden" id="" name="utm_agc" value="[WERT EINFÜGEN]" /><input type="hidden" id="" name="utm_orig_source" value="[WERT EINFÜGEN]" /><input type="hidden" id="" name="utm_campaign" value="[WERT EINFÜGEN]" /><input type="hidden" id="" name="utm_source" value="[WERT EINFÜGEN]" /><input type="email"  placeholder="E-Mail Adresse" name="email" required value="" size="50"/><input type="text" placeholder="Vorname" required name="prename" value="" /><input type="text" placeholder="Nachname" name="lastname" value="" /><input type="text" placeholder="Telefon" name="phone" value="" /><input type="submit" id="FormSubmit" name="FormSubmit" value="go"/></div></form>';

        optinService.getDetailsOptin($stateParams.optinId).then(function(data) {
            $scope.optin = data;
        }, function() {
            console.log('error');
        });

        $scope.saveOptin = function() {
            console.log('SAVE - simulating waiting 1000ms');
            var putdata = $scope.optin;
            console.log(putdata);

            optinService.updateOptin($stateParams.optinId, putdata).then(function(data) {
                console.log(data);
                $mdToast.show(
                    $mdToast.simple()
                    .textContent('Ihre Änderungen wurden gespeichert')
                    .hideDelay(3000))
                $scope.optin = data;
                $scope.tagsReadOnly = false;
            }, function() {
                console.log('error');
            });

        }
        
        $scope.deleteOptin = function() {
            console.log('DELETE -simulating waiting 1000ms');
            $timeout(() => {
                $state.go('app.optins');
            }, 1000) 
        }
        
    }];
    angular.module('dashboard').controller('optinDetailController', optinDetailController);
}());