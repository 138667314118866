(function() {
    var selectController = ['$scope', '$state', 'selectService', function($scope, $state, selectService ) {
        
        console.log('select controller loaded');
        console.log($state.current.name);

        selectService.getSelects().then(function(data) {
            $scope.selects = data;
        }, function() {
            console.log('error');
        });

        $scope.addOptin = function () {
            $state.go('app.addOptin');
        }
        
    }];
    angular.module('dashboard').controller('selectController', selectController);
}());