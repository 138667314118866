(function() {
    var leadsController = ['$scope', '$mdDialog' , 'productService', 'surveyService', function($scope, $mdDialog, productService, surveyService) {

        console.log('leads controller loaded');
        $scope.showFilter = false;
        $scope.toggleFilter = function(){
            $scope.showFilter = !$scope.showFilter;
        }

    }];
    angular.module('dashboard').controller('leadsController', leadsController);
}());