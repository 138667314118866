(function() {
    var productController = ['$scope', '$mdDialog', '$stateParams', '$transitions', 'product', 'productService', function($scope, $mdDialog, $stateParams, $transitions, product, productService) {
        
        $scope.product = product;
        $scope.showFields = false;
        $scope.showLink = false;
        
        $transitions.onSuccess({ to: 'app.product.detail.**' }, function(transition) {
            const state = transition.to().name;

            switch (state) {
                case 'app.product.detail.fields':
                    $scope.showFields = true;
                    $scope.showLink = false;
                    $scope.showAddPlatform = false; 
                  break;
                case 'app.product.detail.surveys':
                    $scope.showFields = false;
                    $scope.showLink = true; 
                    $scope.showAddPlatform = false;  
                    break;
                case 'app.product.detail.platformlinking':
                    $scope.showFields = false;
                    $scope.showLink = false;
                    $scope.showAddPlatform = true;  
                  break;
                default:
                    $scope.showFields = false;
                    $scope.showLink = false;
                    $scope.showAddPlatform = false;  
              }
            
        });

        // dialog to add new link survey product

        $scope.addSurvey = function(ev) {
            $mdDialog.show({
              controller: DialogAddSurveyController,
              templateUrl: 'app/view/dialog/survey.link.add.tmpl.html',
              parent: angular.element(document.body),
              targetEvent: ev,
              clickOutsideToClose:true,
              fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
            })
            .then(function(answer) {
                productService.addSurveyProductLink(answer.id, $stateParams.productId).then(function(data) {
                    // duplicate check
                    
                    if(data.error){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent(angular.element(document.querySelector('#popupContainer')))
                                .clickOutsideToClose(true)
                                .title('Achtung')
                                .textContent(data.msg)
                                .ariaLabel('Alert')
                                .ok('OK')
                            );
                    } else {
                        $scope.$parent.$broadcast('addSurveyProductLink'); 
                    }
                }, function() {
                    console.log('error');
                });

            }, function() {
              $scope.status = 'You cancelled the dialog.';
            });
        };

        function DialogAddSurveyController($scope, $mdDialog, surveyService) {
            $scope.hide = function() {
              $mdDialog.hide();
            };
        
            $scope.cancel = function() {
              $mdDialog.cancel();
            };
            
            // save button function
            $scope.addSurvey = function() {
              $mdDialog.hide($scope.survey);
            };
    
            //load surveys
            surveyService.getSurveys().then(function(data) {
              $scope.surveys = data;
            }, function() {
              console.log('error');
            });
  
          }

        // dialog to add new field

        $scope.addField = function(ev, productIndex, index) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirm = $mdDialog.prompt()
              .title('Neues Feld hinzufügen')
              .textContent('Bitte geben Sie einen Namen ein')
              .placeholder('Titel')
              .ariaLabel('Titel')
              .initialValue('')
              .targetEvent(ev)
              .required(true)
              .ok('Hinzufügen')
              .cancel('Abbrechen');
        
            $mdDialog.show(confirm).then(function(result) {
                productService.addProductApiFields(productIndex, result).then(function(data) {
                    // duplicate check
                    
                    if(data.error){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent(angular.element(document.querySelector('#popupContainer')))
                                .clickOutsideToClose(true)
                                .title('Achtung')
                                .textContent(data.msg)
                                .ariaLabel('Alert')
                                .ok('OK')
                            );
                    } else {
                        $scope.$parent.$broadcast('addData'); 
                    }
                }, function() {
                    console.log('error');
                });



            }, function() {
              $scope.status = 'You didn\'t name your dog.';
            });
          };

          // delete field

          $scope.deleteField = function(id){
            productService.deleteProductApiField(id, $stateParams.productId).then(function(data) {
                // success
                console.log("delete success", data);
                $scope.$parent.$broadcast('deleteData'); 
            }, function() {
                console.log('error');
            });
          }

          
          // dialog to add new link for Sales platform

          $scope.addPlatformLink = function(ev) {
            $mdDialog.show({
              controller: DialogAddSalesplaformLinkController,
              controllerAs: 'ctrl',
              templateUrl: 'app/view/dialog/product.add.link.salesplatform.html',
              parent: angular.element(document.body),
              targetEvent: ev,
              clickOutsideToClose:true,
              fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
            })
            .then(function(answer) {
                productService.addSurveyProductLink(answer.id, $stateParams.productId).then(function(data) {
                    // duplicate check
                    
                    if(data.error){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent(angular.element(document.querySelector('#popupContainer')))
                                .clickOutsideToClose(true)
                                .title('Achtung')
                                .textContent(data.msg)
                                .ariaLabel('Alert')
                                .ok('OK')
                            );
                    } else {
                        $scope.$parent.$broadcast('addSurveyProductLink'); 
                    }
                }, function() {
                    console.log('error');
                });

            }, function() {
              $scope.status = 'You cancelled the dialog.';
            });
        };

        function DialogAddSalesplaformLinkController($scope, $http, $mdDialog, productService) {

            var self = this;

            self.searchText = '';
            self.products = [];
            self.platforms = [];
            self.platform = null;
            self.showAutocomplete = false;

            self.hide = function() {
              $mdDialog.hide();
            };
        
            self.cancel = function() {
              $mdDialog.cancel();
            };
            
            // save button function
            self.addSurvey = function() {
              $mdDialog.hide($scope.survey);
            };
    
            

            //load salesplatform
            productService.getSalesPlatform().then(function(data) {
              console.log('test test platform');
              self.platforms = data;
            }, function() {
              console.log('error');
            });

            // track change of salesplatform

            self.platformChange = function(item){
              self.searchText = '';
              self.products = [];
              self.showAutocomplete = true;
              
              let query = self.searchText;

              if(item.id == 1){
                productService.getGorillaProducts(query).then(function(data) {
                  console.log(data);
                  self.products = data;
                }, function() {
                  console.log('error');
                });
              } else if(item.id == 4){
                productService.getZohoProducts(query).then(function(data) {
                  console.log(data.items);
                  self.products = data.items;
                }, function() {
                  console.log('error');
                });
              } else {
                return []
              }
            }
            
              
              

            self.querySearch = function(query) {
              console.log(self.platform);

              if(self.platform.id == 1){
                productService.getGorillaProducts(query).then(function(data) {
                  console.log(data);
                  self.products = data;
                }, function() {
                  console.log('error');
                });
              } else if(self.platform.id == 4){
                productService.getZohoProducts(query).then(function(data) {
                  console.log(data.items);
                  self.products = data.items;
                }, function() {
                  console.log('error');
                });
              } else {
                return []
              }
              
                
            }

            

          }


    }];
    angular.module('dashboard').controller('productController', productController);
}()); 