(function() {
    var productsController = ['$scope', '$mdDialog' , 'productService', 'surveyService', function($scope, $mdDialog, productService, surveyService) {
        
        $scope.products = [];
        $scope.editable = true;

         function getQuestionById(id, questionArray){
            var result = undefined;
            // check if var is array
            if(Array.isArray(questionArray)){
                for (var i = 0; i < questionArray.length; i++) {
                    if(questionArray[i].link_survey_response_answers_question_alias == id){
                        result = questionArray[i];
                        result.selectedArrayIndex = i;
                        break;
                    }
                }
            }
            return result;
        }

        $scope.toggleEdit = function(){
            $scope.editable = !$scope.editable;    
        }

        $scope.populateProductTable = function(productId){
            console.log('Populate Product Table with ID: ',  productId);
        }
        

        productService.getProducts().then(function(data) {
            $scope.products = data;
        }, function() {
            console.log('error');
        });


        function array_move(arr, old_index, new_index) {
            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing purposes
        };

        function syncApiFieldsOrder(productIndex){

            $scope.products[productIndex].apifields.forEach(function(item, index){
                $scope.products[productIndex].apifields[index].sort = index;
            })
        }

        $scope.saveApiFields = function(productIndex){
            $scope.products[productIndex].apifields.forEach(function(item){
                productService.updateProductApiFields(item.id, item.property, item.sort, item.visible, item.column_name_quicklink, $scope.products[productIndex].id).then(function(data) {
                    // success
                }, function() {
                    console.log('error');
                });
            });
        }

        $scope.moveUp = function(productIndex, index){
            array_move($scope.products[productIndex].apifields, index, (index-1));
            syncApiFieldsOrder(productIndex);
        }

        $scope.moveDown = function(productIndex, index){
            array_move($scope.products[productIndex].apifields, index, (index+1));
            syncApiFieldsOrder(productIndex);
        }

        // dialog to add new link survey product

        $scope.addSurvey = function(ev) {
            $mdDialog.show({
              controller: DialogController,
              templateUrl: 'app/view/dialog/survey.link.add.tmpl.html',
              parent: angular.element(document.body),
              targetEvent: ev,
              clickOutsideToClose:true,
              fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
            })
            .then(function(answer) {
              $scope.status = 'You said the information was "' + answer + '".';
            }, function() {
              $scope.status = 'You cancelled the dialog.';
            });
          };

        // dialog to add new field

        $scope.addField = function(ev, productIndex, index) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirm = $mdDialog.prompt()
              .title('Neues Feld hinzufügen')
              .textContent('Bitte geben Sie einen Namen ein')
              .placeholder('Titel')
              .ariaLabel('Titel')
              .initialValue('')
              .targetEvent(ev)
              .required(true)
              .ok('Hinzufügen')
              .cancel('Abbrechen');
        
            $mdDialog.show(confirm).then(function(result) {
                productService.addProductApiFields(productIndex, result).then(function(data) {
                    // duplicate check
                    
                    if(data.error){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent(angular.element(document.querySelector('#popupContainer')))
                                .clickOutsideToClose(true)
                                .title('Achtung')
                                .textContent(data.msg)
                                .ariaLabel('Alert')
                                .ok('OK')
                            );
                    } else {
                        $scope.products[index].apifields = data;
                    }
                }, function() {
                    console.log('error');
                });



            }, function() {
              $scope.status = 'You didn\'t name your dog.';
            });
          };

          // delete field

          $scope.deleteField = function(id, productId, index){
            productService.deleteProductApiField(id, productId).then(function(data) {
                // success
                console.log('mein index:' + index);
                $scope.products[index].apifields = data;
            }, function() {
                console.log('error');
            });
          }

          $scope.logger = function(text, productId, responsePropId){
              console.log('mein text: ', text + ' ', productId + ' ', responsePropId);
          }


          $scope.clearValue = function(productId, responsePropId, surveyId) {
            surveyService.deleteTsMapping(productId, responsePropId, surveyId).then(function(data) {
                console.log(data);
                // success
            }, function() {
                console.log('error');
            });
          };

          $scope.storeTsMapping = function(question, productId, responsePropId, index, productIndex){
                var postdata = {"productId": productId, 
                        "responsePropId": responsePropId, 
                        "question_alias": question.link_survey_response_answers_question_alias, 
                        "link_surveys": question.link_surveys}

                        console.log($scope.products[productIndex]);

            surveyService.storeTsMapping(postdata).then(function(data) {
                console.log(postdata);
                // success
                console.log(data);
                if(data.error){
                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent(angular.element(document.querySelector('#popupContainer')))
                            .clickOutsideToClose(true)
                            .title('Achtung')
                            .textContent(data.msg)
                            .ariaLabel('Alert')
                            .ok('OK')
                        );
                }
            }, function() {
                console.log('error');
            });



        }

    }];
    angular.module('dashboard').controller('productsController', productsController);
}());