(function () {
    'use strict';
 
    angular.module('dashboard').factory('AuthenticationService', Service);
 
    function Service($http, $localStorage, $window) {
        var service = {};
 
        service.Login = Login;
        service.Logout = Logout;
 
        return service;

        // decode payload function
        function parseJwt(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse($window.atob(base64));
        }
        
        function Login(username, password, callback) {

            var postdata = {email: username, password: password};

            $http({
                method: 'POST',
                data: postdata,
                url: 'https://api.af-media.org/login'
            }).then(function successCallback(response) {
                    if (response.data.token) {
                        // check token payload
                        var payload = parseJwt(response.data.token);
                        // store username and token in local storage to keep user logged in between page refreshes
                        $localStorage.currentUser = { username: username, vorname: payload.vorname, nachname: payload.nachname, token: response.data.token, role: payload.role, expires_at: payload.exp };

                        // add jwt token to auth header for all requests made by the $http service
                        $http.defaults.headers.common.Authorization = 'Bearer ' + response.data.token;

                        // execute callback with true to indicate successful login
                        callback(true);
                    } else {
                        // execute callback with false to indicate failed login
                        callback(false);
                    }
                }, function errorCallback(response) {
                    console.log(response);
                    callback(false);
            }); 

        }

        function Logout() {
            // remove user from local storage and clear http auth header
            delete $localStorage.currentUser;
            $http.defaults.headers.common.Authorization = '';
        }
        
        // checks if user has the permission to move to next state
        function checkPermission() {

        }
    }
})();