(function() {
    var optinAddController = ['$scope', '$state', '$timeout', 'optinService', function($scope, $state, $timeout, optinService ) {
        
        console.log('optin add controller loaded');

        $scope.optin = {};
        $scope.optin.tags = ['Das ist ein Beispiel-Tag bitte entfernen! Einfach einen neuen Wert ins Inputfeld schreiben und ENTER drücken'];

        $scope.addOptin = function(){
            console.log('call api and add data');
           
            var postData = $scope.optin;

            optinService.addOptin(postData).then(function(data) {
                $state.go('app.optins');
            }, function() {
                console.log('error');
            });
 
        }

        $scope.navigateBack = function() {
            $state.go('app.optins');   
        }
    }];
    angular.module('dashboard').controller('optinAddController', optinAddController);
}());