(function() {
    var appController = ['$scope', '$state', '$mdSidenav', '$interval', '$mdDialog', 'settingsService', function($scope, $state, $mdSidenav, $interval, $mdDialog, settingsService) {
    
        let states = $state.get();


        states.forEach(function(state){
            //console.log(state);
            if(state.name.length > 0){
                settingsService.initStates(state.name, state.name, state.description).then(function(data) {
                    //console.log(data);
                }, function() {
                    console.log('error');
                })
            }
        })

        // show dialog internal method 
        function showAlert(text, logout) {
            alert = $mdDialog.alert({
                title: 'Achtung',
                textContent: text,
                ok: 'OK'
            });
  
            $mdDialog
                .show( alert )
                .finally(function() {
                    if(logout){$state.go('login');}
                    alert = undefined;
                });
        }

        // dummy init 
        $scope.user = {};
        $scope.user.role = 1;
        $scope.user.vorname = "Global";
        $scope.user.nachname = "Administrator";
        

        $scope.toggleSidenav = function(menu) {
            $mdSidenav(menu).toggle();
        }       

        $scope.logger = function(string){
            console.log(string);
        }

        var originatorEv;

        $scope.openMenu = function($mdMenu, ev) {
            console.log('menu trigger');
            originatorEv = ev;
            $mdMenu.open(ev);
        };

        $scope.openSubmenu = function(section){
            
            if(section.submenu.length > 0){
                $scope.navSections.forEach(function(element, index) {
                    if(element.name === section.name){
                        console.log(element);
                        console.log(index);
                        $scope.navSections[index].submenuState = !$scope.navSections[index].submenuState;
                    } else {
                        $scope.navSections[index].submenuState = false;    
                    }
                });

            }
        }
    
        $scope.navSections = [{
                            name: 'Dashboard',
                            icon: 'dashboard-black-24dp.svg',
                            link: 'app.home',
                            activ: true,
                            show: ($scope.user.role < 9) ? true : false,
                            submenuState: false,
                            submenu: []
                            }, {
                            name: 'Produkte',
                            icon: 'ic_done_48px.svg',
                            link: 'app.products',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            },{
                                name: 'Sales Platform',
                                icon: 'sales_24dp.svg',
                                link: 'app.salesplatform',
                                activ: true,
                                show: ($scope.user.role < 3) ? true : false,
                                submenuState: false,
                                submenu: []
                            },{
                            name: 'Surveys',
                            icon: 'assignment-black-24dp.svg',
                            link: 'app.surveys',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            },{
                            name: 'Select-API',
                            icon: 'baseline-list-24px.svg',
                            link: 'app.selectapi',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            },{
                            name: 'Optins',
                            icon: 'assignment-black-24dp.svg',
                            link: 'app.optins',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            }, 
                            {
                            name: 'Telesales',
                            icon: 'baseline-call-24px.svg',
                            link: '',
                            activ: true,
                            show: ($scope.user.role < 9) ? true : false,
                            submenuState: false,
                            submenu: [
                                {
                                    name: "TS Dashboard",
                                    icon: 'dashboard-black-24dp.svg',
                                    link: 'app.exportleads',
                                    show: ($scope.user.role < 3) ? true : false,
                                    activ: true
                                }, 
                                {
                                    name: ($scope.user.role < 3) ? 'Leads zuweisen' : 'Meine Leads',
                                    icon: 'baseline-people-24px.svg',
                                    link: 'app.leads',
                                    show: true,
                                    activ: true 
                                }
                            ]
                            },
                            {
                                name: 'Einstellungen',
                                icon: 'ic_settings_48px.svg',
                                link: '',
                                activ: true,
                                show: ($scope.user.role < 9) ? true : false,
                                submenuState: false,
                                submenu: [
                                    {
                                        name: "API Keys",
                                        icon: 'vpn_key-black-24dp.svg',
                                        link: 'app.exportleads',
                                        show: ($scope.user.role < 3) ? true : false,
                                        activ: true
                                    }, 
                                    {
                                        name: 'User Roles',
                                        icon: 'baseline-people-24px.svg',
                                        link: 'app.roles',
                                        show: true,
                                        activ: true 
                                    },
                                    {
                                        name: 'App States',
                                        icon: 'directions-black-24dp.svg',
                                        link: 'app.states',
                                        show: true,
                                        activ: true 
                                    }
                                ]
                            },
                            {
                            name: 'Evaluations',
                            icon: 'assignment-black-24dp.svg',
                            link: 'app.evaluations',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            },
                            {
                            name: 'Rechnungslink',
                            icon: 'assignment-black-24dp.svg',
                            link: 'app.rg-link',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            },
                            {
                            name: 'Zoho Verkäufe',
                            icon: 'assignment-black-24dp.svg',
                            link: 'app.zoho-sales',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            },
                            {
                            name: 'Log',
                            icon: 'baseline-notes-24px.svg',
                            link: 'app.log',
                            activ: true,
                            show: ($scope.user.role < 3) ? true : false,
                            submenuState: false,
                            submenu: []
                            }]
        
    }];
    angular.module('dashboard').controller('appController', appController);
}());