(function () {
    var surveyService = ['$http', function ($http) {

        this.getQuestions = function(surveyId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/survey/'+surveyId+'/questions'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getSurveyApiFields = function(surveyId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/survey/'+surveyId+'/apifields'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.storeTsMapping = function (data) {
            return $http({
            method: 'POST',
            data: data,
            url: 'https://api.af-media.org/survey/storetsmapping'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.deleteTsMapping = function (productId, responsePropId, surveyId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/survey/deletetsmapping/'+productId+'/'+responsePropId+'/'+surveyId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.deleteProductSurveyLink = function (productId, surveyId) {
            return $http({
            method: 'DELETE',
            url: 'https://api.af-media.org/product/link-survey/'+productId+'/'+surveyId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        function getQuestionById(id, questionArray){
            var result = undefined;
            // check if var is array
            if(Array.isArray(questionArray)){
                for (var i = 0; i < questionArray.length; i++) {
                    if(questionArray[i].link_survey_response_answers_question_alias == id){
                        result = questionArray[i];
                        result.selectedArrayIndex = i;
                        break;
                    }
                }
            }
            return result;
        }

        this.getSurveys = function () {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/surveys'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getSurveysForProduct = function (productId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/survey/product/'.productId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getSurvey = function(productId, surveyId) {

            var survey = {};

            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/survey/'+productId+'/'+surveyId+'/apifields'
            }).then(function successCallback(response) {
                
                survey.apiFields = response.data;

                console.log('test', response.data);

                return $http({
                method: 'GET',
                url: 'https://api.af-media.org/survey/'+surveyId+'/questions'
                }).then(function successCallback(response) {

                    var questionArray = response.data;
                    survey.questions = response.data;

                survey.apiFields.forEach(function(field, mykey){
                    var question = getQuestionById(field.link_survey_response_alias_questions, questionArray);
                    survey.apiFields[mykey].question = question;
                })

                return survey;

                }, function errorCallback(response) {
                    console.log(response);
                });  

          

            }, function errorCallback(response) {
                console.log(response);
            });
                        
        }

    }];

    angular.module('dashboard').service('surveyService', surveyService);
  
  } ());