(function() {
    var optinsController = ['$scope', '$state', 'optinService', function($scope, $state, optinService ) {
        
        console.log('optins controller loaded');
        console.log($state.current.name);

        optinService.getOptins().then(function(data) {
            $scope.optins = data;
        }, function() {
            console.log('error');
        });

        $scope.addOptin = function () {
            $state.go('app.addOptin');
        }
        
    }];
    angular.module('dashboard').controller('optinsController', optinsController);
}());