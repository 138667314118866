(function() {
    var mappingDetailController = ['$scope', '$stateParams', 'survey', 'product', 'surveyService', function($scope, $stateParams, survey, product, surveyService) {
                                 
        console.log('api detail mapping controller loaded');

        $scope.survey = survey;
        var productId = $stateParams.productId;

        console.log(product);
        console.log(survey);

        $scope.storeTsMapping = function(question, responsePropId){
            var postdata = {"productId": productId, 
                    "responsePropId": responsePropId, 
                    "question_alias": question.link_survey_response_answers_question_alias, 
                    "link_surveys": question.link_surveys}


            surveyService.storeTsMapping(postdata).then(function(data) {
                console.log(postdata);
                // success
                console.log(data);
                if(data.error){
                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent(angular.element(document.querySelector('#popupContainer')))
                            .clickOutsideToClose(true)
                            .title('Achtung')
                            .textContent(data.msg)
                            .ariaLabel('Alert')
                            .ok('OK')
                        );
                }
            }, function() {
                console.log('error');
            });
        }

    $scope.clearValue = function(responsePropId) {
        surveyService.deleteTsMapping(productId, responsePropId, $stateParams.surveyId).then(function(data) {
            console.log(data);
            // success
        }, function() {
            console.log('error');
        });
      };


    }];
    angular.module('dashboard').controller('mappingDetailController', mappingDetailController);
}());