(function() {
    var evaluationController = ['$scope', '$window', 'evalService', function($scope, $window, evalService) {
        
        console.log('eval controller loaded');

        $scope.startDate = '';
        $scope.endDate = '';
        $scope.timestamp1 = 0;
        $scope.timestamp2 = 0;
        $scope.allowDL =  false;
        $scope.downloadUrl1 = '';
        $scope.downloadUrl2 = '';
        $scope.downloadUrlZoho = '';
        $scope.loadingState1 = 'default';
        $scope.loadingState2 = 'default';
        $scope.loadingStateZoho = 'default';

        $scope.downloadZoho = function(){
            $scope.loadingState = 'loading';
            evalService.getDataZoho($scope.startDate, $scope.endDate).then(function(data) {
                console.log('test', data);
                $scope.downloadUrlZoho = 'https://api.af-media.org'+data.filepath;
                $scope.loadingStateZoho = 'finished';
            }, function() {
                console.log('error');
                $scope.loadingStateZoho = 'finished';
            });
        }

        $scope.downloadOne = function(){
            $scope.loadingState = 'loading';
            evalService.getDataNora($scope.startDate, $scope.endDate).then(function(data) {
                console.log('test', data);
                $scope.downloadUrl1 = 'https://api.af-media.org'+data.filepath;
                $scope.loadingState1 = 'finished';
            }, function() {
                console.log('error');
                $scope.loadingState1 = 'finished';
            });
        }

        $scope.downloadTwo = function(){
            $scope.loadingState = 'loading';
            evalService.getDataRaf().then(function(data) {
                console.log('test', data);
                $scope.downloadUrl2 = 'https://api.af-media.org'+data.filepath;
                $scope.loadingState2 = 'finished';
            }, function() {
                console.log('error');
                $scope.loadingState2 = 'finished';
            });
        }

        $scope.finalDownload = function(url){
            window.open(url, '_blank');
            $scope.loadingState1 = 'default';
            $scope.loadingState2 = 'default';
        }
      
        $scope.$watch(
            function() { return $scope.startDate; },
                function(newValue, oldValue) {
                    if ( newValue !== oldValue ) {
                        $scope.startDate = moment(newValue).format('YYYY-MM-DD');
                        $scope.timestamp1 = moment(newValue).unix();
                    }
                    allowDL();
                }
        );
        
        $scope.$watch(
            function() { return $scope.endDate; },
                function(newValue, oldValue) {
                    if ( newValue !== oldValue ) {
                        $scope.endDate = moment(newValue).format('YYYY-MM-DD');
                        $scope.timestamp2 = moment(newValue).unix();
                    }
                    allowDL();
                }
        );

        var allowDL = function(){
            if($scope.timestamp1 > 0 && $scope.timestamp2 > 0){
                if($scope.timestamp1 < $scope.timestamp2){
                    $scope.allowDL = true;
                } else { 
                    $scope.allowDL = false;    
                }
            } else {
                $scope.allowDL = false;
            }
        }
        
    }];
    angular.module('dashboard').controller('evaluationController', evaluationController);
}());