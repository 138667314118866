(function() {
    
    var app = angular.module('dashboard', ['ui.router', 'ngMaterial', 'ngMessages', 'ngStorage']);
    
    app.run([ '$rootScope', '$state', '$stateParams', '$window', '$http', '$location', '$localStorage', function ($rootScope, $state, $stateParams, $window, $http, $location, $localStorage) {
      $rootScope.$state = $state;
      $rootScope.$stateParams = $stateParams;
      
      $rootScope.goBack = function(){
        $window.history.back();
      };

      // keep user logged in after page refresh
      if ($localStorage.currentUser) {
        $http.defaults.headers.common.Authorization = 'Bearer ' + $localStorage.currentUser.token;
      }

      // redirect to login page if not logged in and trying to access a restricted page
      $rootScope.$on('$locationChangeStart', function (event, next, current) {

        console.log('current', current);
        console.log('next', next);

        var publicPages = ['/login','/resetpassword'];
        var restrictedPage = publicPages.indexOf($location.path()) === -1;
        var requestNewPassword = false;

        const regex = /^\/resetpassword\/(?:[a-z]|\d)+$/g;
        const str = $location.path();
        let m;

        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            if(m.length > 0){
              requestNewPassword = true;
            }
        }

        // check if user requests to reset pw
        
        if(!requestNewPassword){
          
          if ($localStorage.currentUser) {
            if($localStorage.currentUser.expires_at < moment().format('X')){
              $location.path('/login');
            }
          }
          
          if (restrictedPage && !$localStorage.currentUser) {
              $location.path('/login');
          }  
        }

      });

      // http interceptor

      app.factory('authHttpResponseInterceptor',['$injector', '$q', '$location', '$localStorage', '$window', function($injector, $q, $location, $localStorage, $window){
        return {
          response: function(response){

            var token = response.headers('jwt');

            // check if server sends new refresh token

            if(token != null){

              var $http = $injector.get('$http'); 
              
              function parseJwt(token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace('-', '+').replace('_', '/');
                return JSON.parse($window.atob(base64));
              }
              // check token payload
              var payload = parseJwt(token);
              
              // store username and token in local storage to keep user logged in between page refreshes
              $localStorage.currentUser = { username: payload.user, vorname: payload.vorname, nachname: payload.nachname, token: response.data.token, role: payload.role, expires_at: payload.exp };

              // add jwt token to auth header for all requests made by the $http service
              $http.defaults.headers.common.Authorization = 'Bearer ' + token;
            }

            if (response.status === 401) {
              console.log("Response 401", response);
            }
            return response || $q.when(response);
          },
          responseError: function(rejection) {
            if (rejection.status === 401) {
              console.log("Response Error 401",rejection);
              console.log('location Test', $location);
              $location.path('/login').search('returnTo', $location.path());
            }
            console.log("TEST", rejection);
            return $q.reject(rejection);
          }
        }
      }])

    .config(['$httpProvider',function($httpProvider) {
      //Http Intercpetor to check auth failures for xhr requests
      $httpProvider.interceptors.push('authHttpResponseInterceptor');
    }])



    }])

    // theme options
    
    app.config(function($mdThemingProvider, $httpProvider) {

      var newGreen = $mdThemingProvider.extendPalette('green', {
        '50': '#3F5866',
        '100': '#FFFFFF',
        '200': '#ffffff',
        '300': '#3F5866',
        '400': '#3F5866',
        '500': '#3F5866',
        '600': '#3F5866',
        '700': '#2B3A42',
        '800': '#2B3A42',
        '900': '#ffffff'
      });

      $mdThemingProvider.definePalette('newGreen', newGreen);

      $mdThemingProvider
      .theme('default')
      .primaryPalette('newGreen', {
        'default': '600'
      })   
      .accentPalette('newGreen', {
        'default': '600'
      })
      .warnPalette('newGreen', {
        'default': '100'
      })

      // register alt theme

      var helpColor = $mdThemingProvider.extendPalette('teal', {
          '500': 'aaaaaa'
      });

      $mdThemingProvider.definePalette('helpColor', helpColor);

      $mdThemingProvider.definePalette('contrastColorColor', {
        '50': 'ffebee',
        '100': 'ffcdd2',
        '200': 'ef9a9a',
        '300': 'e57373',
        '400': 'ef5350',
        '500': 'ffffff',
        '600': 'e53935',
        '700': 'd32f2f',
        '800': 'c62828',
        '900': 'b71c1c',
        'A100': 'ff8a80',
        'A200': 'ff5252',
        'A400': 'ff1744',
        'A700': 'd50000',
        'contrastDefaultColor': 'dark',    // whether, by default, text (contrast)
                                            // on this palette should be dark or light
    
        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
         '200', '300', '400', 'A100'],
        'contrastLightColors': undefined    // could also specify this if default was 'dark'
      });

      $mdThemingProvider.theme('altTheme')
        .primaryPalette('contrastColorColor')
        .accentPalette('orange')
        .warnPalette('red');

    });


    // ============== routing ============== // 
    
    app.config(function($stateProvider, $urlRouterProvider) {
    
    $stateProvider    
    
      .state('login',{
        url: '/login',
        views: {
          'app': {
              templateUrl: 'app/view/login.html',
              controller: 'loginController',
              controllerAs: 'vm'
              }
        }  
          
      })

      .state('app',{
          url: '/app',
          views: {
              'app': {
                  templateUrl: 'app/view/main.html',
                  controller: 'appController'
                  }
          }           
      })
     
      .state('app.notfound',{
        url: '/404',
        data : { pageTitle: 'AFM Admin Dashboard' },
        views: {
          'sidenav': {
            templateUrl: 'app/view/sidebar/sidebar.html',
            controller: ''
          },
          'toolbar': {
            templateUrl: 'app/view/toolbar/toolbar.html',
            controller: ''
          },
          'main': {
            templateUrl: 'app/view/content/home.html',
            controller: ''
          }
        }
    })

      .state('app.home',{
          url: '/',
          data : { pageTitle: 'AFM Admin Dashboard' },
          views: {
            'sidenav': {
              templateUrl: 'app/view/sidebar/sidebar.html',
              controller: ''
            },
            'toolbar': {
              templateUrl: 'app/view/toolbar/toolbar.html',
              controller: ''
            },
            'main': {
              templateUrl: 'app/view/content/home.html',
              controller: ''
            }
          }
      })
              
    .state('app.user',{
      url: '/user',
      data : { pageTitle: 'EIB Sales - User' },
      views: {
        'sidenav': {
          templateUrl: 'app/view/sidebar/sidebar.html',
          controller: ''
        },
        'toolbar': {
          templateUrl: 'app/view/toolbar/toolbar.html',
          controller: ''
        },
        'main': {
          templateUrl: 'app/view/content/user.html',
          controller: 'userController'
        }
      }
  })

// ############################################# //
//                  PRODUCTS                     //
// ############################################# //

  .state('app.products',{
    url: '/products',
    data : { pageTitle: 'Produkte' },
    views: {
      'sidenav': {
        templateUrl: 'app/view/sidebar/sidebar.html',
        controller: ''
      },
      'toolbar': {
        templateUrl: 'app/view/toolbar/toolbar.html',
        controller: ''
      },
      'main': {
        templateUrl: 'app/view/content/products/products.html',
        controller: 'productsController'
      }
    },
    resolve: {
      navItems: function(productService) {
        return productService.getProducts();
      }
    }
})

.state('app.product',{
  url: '/product',
  data : { pageTitle: 'Produkt' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'subnav': {
      templateUrl: 'app/view/subnav/subnav.html',
      controller: 'subnavController'
      },
    'main': {
      templateUrl: 'app/view/content/products/test.html',
      controller: ''
    }
  },
  resolve: {
    navItems: function(productService) {
      return productService.getProducts();
    }
  }
})

.state('app.product.detail',{
  url: '/:productId',
  data : { pageTitle: 'Produkt' },
  views: {
    'product': {
      templateUrl: 'app/view/content/products/product.html',
      controller: 'productController'
    }
  },
  resolve: {
    product: ['$stateParams', 'productService', function($stateParams, productService){
      return productService.getProduct($stateParams.productId);
    }]
  }
})



.state('app.product.detail.settings',{
  url: '/settings',
  data : { pageTitle: 'Produkt - Einstellungen' },
  views: {
    'detailview': {
      templateUrl: 'app/view/content/products/settings.html',
      controller: 'productSettingsController'
    }
  }
})

.state('app.product.detail.fields',{
  url: '/apifields',
  data : { pageTitle: 'Produkt - API Felder' },
  views: {
    'detailview': {
      templateUrl: 'app/view/content/products/fields.html',
      controller: 'apifieldController'
    }
  }
})

.state('app.product.detail.zohofields',{
  url: '/zohoapifields',
  data : { pageTitle: 'Produkt - Zoho API Felder' },
  views: {
    'detailview': {
      templateUrl: 'app/view/content/products/zohofields.html',
      controller: 'zohoApifieldController'
    }
  }
})

.state('app.product.detail.surveys',{
  url: '/surveys',
  data : { pageTitle: 'Produkt - Surveys' },
  views: {
    'detailview': {
      templateUrl: 'app/view/content/products/surveys.html',
      controller: 'productSurveyController'
    }
  },
  resolve: {
    surveys: ['$stateParams', 'productService', function($stateParams, productService){
      return productService.getProductSurveys($stateParams.productId);
    }]
  }
})

.state('app.product.detail.mapping',{
  url: '/apimapping',
  data : { pageTitle: 'Produkt - API Mapping' },
  views: {
    'detailview': {
      templateUrl: 'app/view/content/products/mapping.html',
      controller: 'mappingController'
    }
  },
  resolve: {
    surveys: ['$stateParams', 'productService', function($stateParams, productService){
      return productService.getProductSurveys($stateParams.productId);
    }]
  }
})

.state('app.product.detail.mapping.detail',{
  url: '/detail/:surveyId',
  data : { pageTitle: 'Produkt - API Mapping' },
  views: {
    'mapping-detail': {
      templateUrl: 'app/view/content/products/mapping-detail.html',
      controller: 'mappingDetailController'
    }
  },
  resolve: {
    survey: ['$stateParams', 'surveyService', function($stateParams, surveyService){
      return surveyService.getSurvey($stateParams.productId, $stateParams.surveyId);
    }]
  }
})

.state('app.product.detail.platformlinking',{
  url: '/zohoapifields',
  data : { pageTitle: 'Produkt - Sales Platform Linking' },
  views: {
    'detailview': {
      templateUrl: 'app/view/content/products/platform-linking.html',
      controller: ''
    }
  }
})

// ############################################# //
//                  PRODUCTS                     //
// ############################################# //

.state('app.salesplatform',{
  url: '/salesplatform',
  data : { pageTitle: 'Sales Platform' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/salesplatform/sales_platforms.html',
      controller: 'salesplatformController'
    }
  },
  resolve: {
    platforms: ['productService', function(productService){
      return productService.getSalesPlatform();
    }]
  }
})


// SURVEYS

.state('app.surveys',{
  url: '/surveys',
  data : { pageTitle: 'Surveys' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/surveys/surveys.html',
      controller: 'surveysController'
    }
  },
  resolve: {
  }
})

.state('app.surveys.detail',{
  url: '/:surveyId',
  data : { pageTitle: 'Edit Survey' },
  views: {
    'detail': {
      templateUrl: 'app/view/content/surveys/detail.html',
      controller: ''
    }
  },
  resolve: {
  }
})

// SELECT API

.state('app.selectapi',{
  url: '/select-api',
  data : { pageTitle: 'Select API' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/select-api/select.html',
      controller: 'selectController'
    }
  },
  resolve: {
  }
})

.state('app.selectapi.detail',{
  url: '/:selectId',
  data : { pageTitle: 'Edit Select Field' },
  views: {
    'detail': {
      templateUrl: 'app/view/content/select-api/detail.html',
      controller: 'selectDetailController'
    }
  },
  resolve: {
  }
})


// OPTINS

.state('app.optins',{
  url: '/optins',
  data : { pageTitle: 'Optins' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/optins/optins.html',
      controller: 'optinsController'
    }
  },
  resolve: {
  }
})

.state('app.addOptin',{
  url: '/add-optin',
  data : { pageTitle: 'Add Optin' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/optins/add.html',
      controller: 'optinAddController'
    }
  },
  resolve: {
  }
})

.state('app.optins.detail',{
  url: '/:optinId',
  data : { pageTitle: 'Edit Optin' },
  views: {
    'detail': {
      templateUrl: 'app/view/content/optins/detail.html',
      controller: 'optinDetailController'
    }
  },
  resolve: {
  }
})

// USER ROLES

.state('app.roles',{
  url: '/settings/roles',
  data : { pageTitle: 'Einstellungen - User Roles' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/roles/roles.html',
      controller: 'rolesController'
    }
  },
  resolve: {
  }
})

.state('app.roles.detail',{
  url: '/:role',
  data : { pageTitle: 'Einstellungen - User Roles' },
  views: {
    'detail': {
      templateUrl: 'app/view/content/roles/detail.html',
      controller: ''
    }
  },
  resolve: {
  }
})

.state('app.states',{
  url: '/settings/states',
  data : { pageTitle: 'Einstellungen - App States' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/states.html',
      controller: 'statesController'
    }
  },
  resolve: {
    states: ['settingsService', function(settingsService){
      return settingsService.getAllStates();
    }]
  }
})

.state('app.evaluations',{
  url: '/evaluations',
  data : { pageTitle: 'Evaluations' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/evaluations/eval.html',
      controller: 'evaluationController'
    }
  }
})

.state('app.rg-link',{
  url: '/rg-link',
  data : { pageTitle: 'Rechnungslink Suche' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/rg-link/rg-link.html',
      controller: 'rechnungslinkController'
    }
  }
})

.state('app.zoho-sales',{
  url: '/zoho-sales',
  data : { pageTitle: 'Zoho Verkäufe Download' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/zoho-sales/zoho-sales.html',
      controller: 'zohosalesController'
    }
  }
})

.state('app.log',{
  url: '/log',
  data : { pageTitle: 'Log' },
  views: {
    'sidenav': {
      templateUrl: 'app/view/sidebar/sidebar.html',
      controller: ''
    },
    'toolbar': {
      templateUrl: 'app/view/toolbar/toolbar.html',
      controller: ''
    },
    'main': {
      templateUrl: 'app/view/content/log.html',
      controller: 'logController'
    }
  }
});
        
$urlRouterProvider.otherwise('/login');

});

    
 
}());
