(function() {
    var mappingController = ['$scope', '$state', '$stateParams', 'surveyService', 'surveys', function($scope, $state, $stateParams, surveyService, surveys) {

        console.log('api mapping controller loaded');

        $scope.surveys = surveys;
        $scope.surveyId = $stateParams.surveyId;

        $scope.openSurvey = function(surveyId){
            console.log(surveyId);
            $state.go('app.product.detail.mapping.detail', {surveyId: surveyId});
        }

    }];
    angular.module('dashboard').controller('mappingController', mappingController);
}());