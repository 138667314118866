(function() {
    var rechnungslinkController = ['$scope', '$window', 'rglinkService', function($scope, $window, rglinkService) {
        
        console.log('rg link controller loaded');

        $scope.rgnummer = null;
        $scope.mollieReturn = null;

        $scope.zohoRgNummer = null;
        $scope.zohoMollieReturn = null;

        $scope.zohoLoading = false;
        
        $scope.getMollieData = function(){
            console.log("Clicked Mollie");

            rglinkService.getMollieData($scope.rgnummer).then(function(data) {
                console.log('molliedata', data);
                $scope.mollieReturn = data;
            }, function() {
                console.log('error');
            });
        } 
        
        $scope.getMollieDataByZohoId = function(){
            $scope.zohoLoading = true;
            console.log("Clicked Zoho");

            rglinkService.getMollieLinkForZohoID($scope.zohoRgNummer).then(function(data) {
                console.log('Zohomolliedata', data);
                $scope.zohoMollieReturn = data;
                $scope.zohoLoading = false;
            }, function() {
                console.log('error');
                $scope.zohoLoading = false;
            });
        } 
        
    }];
    angular.module('dashboard').controller('rechnungslinkController', rechnungslinkController);
}());