(function() {
    var productSurveyController = ['$scope', '$state', '$stateParams', 'surveys', 'surveyService', 'productService', function($scope, $state, $stateParams, surveys, surveyService, productService) {
            
        console.log('productSurveyController loaded');

        $scope.surveys = surveys;

        $scope.deleteLink = function(surveyId){
            surveyService.deleteProductSurveyLink( $stateParams.productId, surveyId).then(function(data) {
                $scope.surveys = data;
            }, function() {
                console.log('error');
            });
        };

        $scope.$on('addSurveyProductLink', function (data) {
            console.log('added data');
            
            productService.getProductSurveys($stateParams.productId).then(function(data) {
                $scope.surveys = data;
            }, function() {
                console.log('error');
            });

        });
           
        

    }];
    angular.module('dashboard').controller('productSurveyController', productSurveyController);
}());