(function() {
    var apifieldController = ['$scope', '$stateParams', '$mdToast', 'productService', function($scope, $stateParams, $mdToast, productService) {

        $scope.editable = false;

        var loadData = function(){
            productService.getProductApiFields($stateParams.productId).then(function(data) {
                console.log(data);
                $scope.apifields = data;
            }, function() {
                console.log('error');
            });
        }
        
        loadData();

        function array_move(arr, old_index, new_index) {
            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing purposes
        };

        function syncApiFieldsOrder(){
            $scope.apifields.forEach(function(item, index){
                $scope.apifields[index].sort = index;
            })
        }

        $scope.moveUp = function(index){
            array_move($scope.apifields, index, (index-1));
            syncApiFieldsOrder();
        }

        $scope.moveDown = function(index){
            array_move($scope.apifields, index, (index+1));
            syncApiFieldsOrder();
        }

        $scope.saveApiFields = function(){
            $scope.apifields.forEach(function(item){
                productService.updateProductApiFields(item.id, item.property, item.sort, item.visible, item.column_name_quicklink, $stateParams.productId).then(function(data) {
                    // success
                    
                }, function() {
                    console.log('error');
                });
            });
            $mdToast.show($mdToast.simple().textContent('Ihre Änderungen wurden gespeichert!'));
        }

        $scope.$on('deleteData', function (data) {
            console.log('receive data from edelte event');
            loadData();
        });

        $scope.$on('addData', function (data) {
            console.log('added data');
            loadData();
        });
        
    }];
    angular.module('dashboard').controller('apifieldController', apifieldController);
}());