(function() {
    var rolesController = ['$scope', '$stateParams', function($scope, $stateParams) {
        console.log('roles controller loaded');
        
        $scope.roles = [
            {role: "Administrator",
            desc: "Lorem Ipsumn"},
            {role: "Supervisor",
            desc: "Lorem Ipsumn"},
            {role: "TS Supervisor",
            desc: "Lorem Ipsumn"},
            {role: "TS Salesagent",
            desc: "Lorem Ipsumn"},
            {role: "User",
            desc: "Lorem Ipsumn"},
            {role: "API Consumer",
            desc: "Lorem Ipsumn"}
        ];
        
    }];
    angular.module('dashboard').controller('rolesController', rolesController);
}());