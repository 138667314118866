(function () {
    var selectService = ['$http', function ($http) {

        this.getSelects = function() {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/select/types'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getDetailsSelect = function(typeId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/select/answers/'+typeId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getDetailsSelectFilterByParent = function(parentId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/select/filterByParent/'+parentId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getSubSelectParents = function() {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/select/subfields'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.addSelectAnswer = function (data) {
            return $http({
            method: 'POST',
            data: data,
            url: 'https://api.af-media.org/select/answers'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log('error ', response);
            });  
        }

        this.deleteSelectItem = function(id) {
            return $http({
            method: 'DELETE',
            url: 'https://api.af-media.org/select/'+id
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log('error ', response);
            });  
        }

    }];

    angular.module('dashboard').service('selectService', selectService);
  
  } ());