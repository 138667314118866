(function() {
    var zohoApifieldController = ['$scope', '$stateParams', '$mdToast', 'productService', function($scope, $stateParams, $mdToast, productService) {

        console.log('Zoho API field controller loaded.');
        $scope.loading = true;

        var productId = $stateParams.productId;
        $scope.moduleLeads = {name: "Leads", value: "leads"};
        $scope.moduleUmfrageergebnisse = {name: "Umfrageergebnisse", value: "umfrageergebnisse"};
        $scope.moduleKontakte = {name: "Kontakte", value: "contacts"};
        
        $scope.zohoModule = $scope.moduleLeads;
        

        var loadData = function(module){
            $scope.loading = true;
            productService.getProductApiFields(productId).then(function(data) {
                
                productService.getZohoApiMapping(module, productId).then(function(apiMappings) {
                    apiMappings.forEach(function(mapping){
                        data.forEach(function(item, key){
                            if(item.id == mapping.link_product_response_properties){
                                data[key].mapping = mapping;
                            }
                        })
                    })

                console.log(data);
                $scope.apifields = data;
                $scope.loading = false;
                }, function() {
                    console.log('error');
                    $scope.loading = false;
                });

            }, function() {
                console.log('error');
            });

            console.log('-------> 33333333333337')
            console.log($scope.zohoModule.value);

            if($scope.zohoModule.value == "umfrageergebnisse" && productId == 33){
                productService.getZohoApiFieldsUmfrageergebnisse($scope.zohoModule.value, productId).then(function(data) {
                    console.log('TEST PK 13333333333333333333337')
                    console.log(data);
                    $scope.zohoApiFields = data;
                }, function() {
                    console.log('error');
                });   
            } else {
                productService.getZohoApiFields($scope.zohoModule.value).then(function(data) {
                    $scope.zohoApiFields = data;
                }, function() {
                    console.log('error');
                });
            }
        }
        
        loadData($scope.zohoModule.value);

        $scope.loadData = function(module){
            loadData(module);    
        }
        
        $scope.createZohoApiMapping = function(propertyFieldId, zohoModule, zohoFieldId, label, apiName){
            productService.createZohoApiMapping(productId, propertyFieldId, zohoModule, zohoFieldId, label, apiName).then(function(data) {
                console.log(data);
                loadData($scope.zohoModule.value);
            }, function() {
                console.log('error');
            });    
        }

        $scope.clearValue = function(propertyFieldId){
            productService.deleteZohoApiMapping($scope.zohoModule.value, propertyFieldId, productId).then(function(data) {
                console.log(data);
                loadData($scope.zohoModule.value);
            }, function() {
                console.log('error');
            });    
        }
        
    }];
    angular.module('dashboard').controller('zohoApifieldController', zohoApifieldController);
}());