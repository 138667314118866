(function () {
    var optinService = ['$http', function ($http) {

        this.getOptins = function() {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/admin/optin/listOptins'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getDetailsOptin = function(optinId) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/admin/optin/optinDetail/'+optinId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.addOptin = function (data) {
            return $http({
            method: 'POST',
            data: data,
            url: 'https://api.af-media.org/admin/optin/addOptin'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log('error ', response);
            });  
        }

        this.updateOptin = function (optinId, data) {
            return $http({
            method: 'PUT',
            data: data,
            url: 'https://api.af-media.org/admin/optin/editOptin/'+optinId
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log('error ', response);
            });  
        }

    }];

    angular.module('dashboard').service('optinService', optinService);
  
  } ());