(function () {
    var rglinkService = ['$http', function ($http) {

        this.getMollieData = function(invoiceNumber) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/payment/mollieSearchInvoice/'+invoiceNumber+'/'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }   
        
        this.getMollieLinkForZohoID = function(invoiceNumber) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/payment/orderNumber/'+invoiceNumber
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }   
    }];

    angular.module('dashboard').service('rglinkService', rglinkService);
  
  } ());