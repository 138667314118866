(function () {
    var evalService = ['$http', function ($http) {

        this.getDataZoho = function(startDate, endDate) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/evaluations/zoho/purchases/'+startDate+'/'+endDate
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }
        
        this.getDataNora = function(startDate, endDate) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/evaluations/nora/'+startDate+'/'+endDate
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        this.getDataRaf = function(startDate, endDate) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/evaluations/raf_exportrecommendation'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            });  
        }

        

    }];

    angular.module('dashboard').service('evalService', evalService);
  
  } ());