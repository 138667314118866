(function () {
    var settingsService = ['$http', function ($http) {

        this.getAllStates = function(state) {
            return $http({
            method: 'GET',
            url: 'https://api.af-media.org/settings/states'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            }); 
        }

        this.initStates = function(state) {
            return $http({
            method: 'POST',
            data: {state: state},
            url: 'https://api.af-media.org/settings/initstates'
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
            }); 
        }


    }];

    angular.module('dashboard').service('settingsService', settingsService);
  
  } ());