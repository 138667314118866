(function() {
    var surveysController = ['$scope', '$stateParams', 'surveyService', 'productService', function($scope, $stateParams, surveyService, productService) {
        
        console.log('surveys controller loaded');

        productService.getProducts().then(function(data) {
            $scope.products = data;
        }, function() {
            console.log('error');
        });

        surveyService.getSurveys().then(function(data) {
            $scope.surveys = data;
        }, function() {
            console.log('error');
        });
        
    }];
    angular.module('dashboard').controller('surveysController', surveysController);
}());